import { toast } from "react-toastify";
import React, { useReducer } from "react";
import ApiWithoutAuth from "../Config/Api";
import AssetContext from "./index";
import AssetReducer from "./reducer";
import { GET_ALL, GET } from "./types";

const ServiceState = ({ children }) => {
  const initialState = {
    data: [],
    loading: false,
  };

  const [state, dispatch] = useReducer(AssetReducer, initialState);

  const getAllService = async (path, parameter) => {
    try {
      const data = await ApiWithoutAuth.getData(`/${path}`, parameter);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL, payload: data?.data?.data });
      } else {
        toast.error(data?.data?.message);
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getService = async (path, id, parameter) => {
    try {
      const data = await ApiWithoutAuth.getData(`/${path}/${id}`, parameter);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET, payload: data?.data?.data });
      } else {
        toast.error(data?.data?.message);
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const submit = async (url, parameter) => {
    try {
      const data = await ApiWithoutAuth.postData(url, parameter);
      if (data?.data?.error === false || !data?.data?.error) {
        return data?.data;
      } else {
        toast.error(data?.data?.message);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data, loading } = state;

  return (
    <AssetContext.Provider
      value={{
        data,
        loading,
        getAllService,
        getService,
        submit,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};

export default ServiceState;
