import { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import Logo2 from "../Assets/logo2.png";
import { useTranslate } from "react-translate";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from "../Assets/back.svg";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ECatalogDetailPage(params) {
  let navigate = useNavigate();
  let { id } = useParams();
  let t = useTranslate(params?.checked ? "id" : "en");
  var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  var height =
    window.innerWidth > 0 ? window.innerHeight : window.screen.height;
  const [widthValue, setWidthValue] = useState(800);
  const [heightValue, setHeightValue] = useState(800);

  useEffect(() => {
    setWidthValue(width - 50);
    setHeightValue(height - 100);
  }, [width, height]);

  const data = [
    {
      id: 1,
      file: "https://drive.google.com/file/d/1lWZgdt_v9f_CM77-ThKaFEhzsETsi6-W/preview",
    },
    {
      id: 2,
      file: "https://drive.google.com/file/d/1C_GJVlQUAG6A4qJM9o_9xlpz_2eyk6xt/preview",
    },
    {
      id: 3,
      file: "https://drive.google.com/file/d/1-DBm3Rw4cpPjFmXsq6jgkA-I9ljsYgZu/preview",
    },
    {
      id: 4,
      file: "https://drive.google.com/file/d/14OhjHQ4k-6n-HaZYmfWOkY2nhmAFHzBa/preview",
    },
    {
      id: 5,
      file: "https://drive.google.com/file/d/13bJDA0aIo82EMzFuSrGe3kSKkjh3FHt-/preview",
    },
    {
      id: 6,
      file: "https://drive.google.com/file/d/1fQ3PYwCURqWMMu6NxKG1ajBPNlqH0KA7/preview",
    },
    {
      id: 7,
      file: "https://drive.google.com/file/d/1R7Lyt5R9UACpst_QLvgMCa-GUvWGxRiGpreview",
    },
    {
      id: 8,
      file: "https://drive.google.com/file/d/1c9keVnzXeYMiGYCcoceJ4bWfyR7L6UzZ/preview",
    },
    {
      id: 9,
      file: "https://drive.google.com/file/d/1dRbnV53uLiUspdL7KfUFd3u5DB3UO4yb/preview",
    },
    {
      id: 10,
      file: "https://drive.google.com/file/d/1M-sVbZwUG3hm50NQlkpDg_4SOSCRg2bd/preview",
    },
    {
      id: 11,
      file: "https://drive.google.com/file/d/1u5wpH-_BM42y6NvAcxllBwyZrA000RbO/preview",
    },
    {
      id: 12,
      file: "https://drive.google.com/file/d/1Q2JXBPZcDTtrAR3dR8abMMwMleJ-dP9R/preview",
    },
    {
      id: 13,
      file: "https://drive.google.com/file/d/1M4Jmmx8Z5KyFlp8Fxc-R7Z5m-GSNmpHI/preview",
    },
    // {
    //   id: 14,
    //   file: "https://drive.google.com/file/d/14XNRF7JasPKAWnzhKjn3GmpuzLQy6Ovf/preview",
    // },
    {
      id: 15,
      file: "https://drive.google.com/file/d/1sFksTucUFFx1vm8yR7NYD1T3qIQfapzd/preview",
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2 bg-header">
        <div className="col-6">
          <img src={Logo2} alt="" />
        </div>
        <div className="contact-form-title col-6">
          <div className="d-flex align-items-center justify-content-end e-catalog">
            <div className="me-5">E-Catalog</div>
            <div
              className="btn btn-secondary text-center px-1"
              onClick={() => navigate(-1)}
            >
              <img src={BackIcon} className="back-icon my-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="layout-catalog">
        <iframe
        sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
          src={data?.[id - 1]?.file}
          style={{ width: "100%", height: heightValue }}
          title="catalog"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
}

export default ECatalogDetailPage;
