import { useContext, useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import Logo2 from "../Assets/logo2.png";
import moment from "moment";
import ServiceContext from "../Services";

function DashboardPage(params) {
  const { loading, getAllService } = useContext(ServiceContext);
  const [data, setData] = useState([]);
  const [tanggal, setTanggal] = useState("");

  const getData = async (tanggal) => {
    let response = await getAllService("guestbook", { tanggal: tanggal });
    if (!response?.message) {
      setData(response);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeDate = (evt) => {
    setTanggal(evt?.target?.value);
    getData(evt?.target?.value);
  };

  return (
    <div className="container">
      <div className="h3 mt-4 mb-3">Dashboard</div>
      <div className="container">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center mb-3">
            <div className="label me-4">Tanggal Login</div>
            <div>
              <input
                type="date"
                onChange={onChangeDate}
                className="form-control"
              />
            </div>
          </div>
          <div>
            <a
              target="_blank"
              className="btn btn-primary"
              href={`${process.env.REACT_APP_BACKEND_URL}api/cetak?tanggal=${tanggal}`}
              rel="noreferrer"
            >
              Download Excel
            </a>
          </div>
        </div>
        <table className="table table-striped rounded">
          <thead className="thead-dark">
            <tr>
              <th>Tanggal Login</th>
              <th>Nama Lengkap</th>
              <th>Nomor Telepon</th>
              <th>Instansi</th>
              <th>Bagian</th>
              <th>Inquiry</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr>
                <td>
                  {moment(item?.created_at).format("DD-MM-YYYY HH:mm:ss")}
                </td>
                <td>{item?.nama}</td>
                <td>{item?.no_telepon}</td>
                <td>{item?.instansi}</td>
                <td>{item?.bagian}</td>
                <td>{item?.detail?.pertanyaan}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DashboardPage;
