import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ServiceContext from "../Services";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import BackIcon from "../Assets/back.svg";
import { toast } from "react-toastify";
import { useTranslate } from "react-translate";
import Logo2 from "../Assets/logo2.png";

const schema = yup
  .object({
    pertanyaan: yup.string().required(),
    // is_add_question_1: yup.string().required(),
    // is_add_question_2: yup.string().required(),
  })
  .required();

function InquiryPage(params) {
  let t = useTranslate(params?.checked ? "id" : "en");
  const { loading, submit } = useContext(ServiceContext);
  let navigate = useNavigate();

  const [dataSubmit, setDataSubmit] = useState({
    pertanyaan: "",
    is_add_question_1: "",
    is_add_question_2: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (values) => {
    let response = await submit("submit-inquiry", values);
    if (!response?.message) {
      console.log(response);
      toast.success(t("submit_inquiry"));
      navigate("/option");
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      setDataSubmit({
        pertanyaan: value?.pertanyaan,
      })
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div>
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex align-items-center justify-content-between mb-2 bg-header">
          <div className="col-6">
            <img src={Logo2} alt="" />
          </div>
          <div className="contact-form-title col-6">
            <div className="d-flex align-items-center justify-content-end e-catalog">
              <div className="me-5">Inquiry</div>
              <div
                className="btn btn-secondary text-center px-1"
                onClick={() => navigate(-1)}
              >
                <img src={BackIcon} className="back-icon my-auto" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          <div className="my-3 text-center">{t("inquiry_desc")}</div>
          <hr className="hr"></hr>

          <div className="mb-3">
            <label for="pertanyaan" className="form-label">
              {t("question")}
            </label>
            <textarea
              {...register("pertanyaan")}
              className="form-control"
              id="pertanyaan"
              rows={5}
              required
            ></textarea>
            <small className="text-danger">{errors.pertanyaan?.message}</small>
          </div>

          {/* <div className="mb-3">
            <label for="pertanyaan" className="form-label">
              {t("inquiry_desc2")} *
            </label>
            <div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  {...register("is_add_question_1")}
                  id="flexRadioDefault1"
                  value="Ya"
                  required
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Pernah
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  {...register("is_add_question_1")}
                  id="flexRadioDefault2"
                  value="Tidak"
                  required
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Tidak Pernah
                </label>
              </div>
            </div>
            <small className="text-danger">
              {errors.is_add_question_1?.message}
            </small>
          </div>

          <div className="mb-3">
            <label for="pertanyaan" className="form-label">
              {t("inquiry_desc3")} *
            </label>
            <div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  {...register("is_add_question_2")}
                  id="flexRadioDefault3"
                  value="Ya"
                  required
                />
                <label class="form-check-label" for="flexRadioDefault3">
                  Pernah
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  {...register("is_add_question_2")}
                  id="flexRadioDefault4"
                  value="Tidak"
                  required
                />
                <label class="form-check-label" for="flexRadioDefault4">
                  Tidak Pernah
                </label>
              </div>
            </div>
            <small className="text-danger">
              {errors.is_add_question_2?.message}
            </small>
          </div> */}

          <div className="container-contact2-form-btn">
            <div className="wrap-contact2-form-btn">
              <div className="contact2-form-bgbtn"></div>
              <button
                className="contact2-form-btn"
                disabled={loading}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default InquiryPage;
