function Template(params) {
  return (
    <div className="bg-contact2">
      <div className={"container-contact2 " + params?.className}>
        {params?.children}
      </div>
    </div>
  );
}
export default Template;
