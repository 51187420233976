import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ServiceContext from "../Services";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Logo2 from "../Assets/logo2.png";
import Background from "../Assets/background.png";
import Template from "../Template";
import { toast } from "react-toastify";
import ReactSwitch from "react-switch";
import { useTranslate } from "react-translate";

const schema = yup
  .object({
    nama: yup.string().required(),
    instansi: yup.string().required(),
    bagian: yup.string().required(),
    no_telepon: yup.string().required(),
  })
  .required();

function GuestBookPage(params) {
  let t = useTranslate(params?.checked ? "id" : "en");
  const { loading, submit } = useContext(ServiceContext);
  let navigate = useNavigate();

  const handleChange = (val) => {
    params.setChecked(val);
  };

  const [dataSubmit, setDataSubmit] = useState({
    nama: "",
    instansi: "",
    bagian: "",
    no_telepon: "",
    pertanyaan: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (values) => {
    let response = await submit("submit", dataSubmit);
    if (!response?.message) {
      if (values?.pertanyaan) {
        let response2 = await submit("submit-inquiry", {
          pertanyaan: values?.pertanyaan,
        });
        if (!response2?.message) {
          toast.success(t("submit"));
          navigate("/option");
        }
      } else {
        toast.success(t("submit"));
        navigate("/option");
      }
    }
  };

  // const getData = async () => {
  //   const res = await axios.get("https://api.ipify.org/?format=json");
  //   if (res.data.ip) {
  //     let response = await getAllService("check-ip");
  //     if (!response?.message) {
  //       if (response?.ip_address === res.data.ip) {
  //         navigate("/option");
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      setDataSubmit({
        nama: value?.nama,
        instansi: value?.instansi,
        bagian: value?.bagian,
        no_telepon: value?.no_telepon,
      })
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Template>
      <div className="wrap-contact2">
        <form
          className="contact2-form validate-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="d-flex align-items-center justify-content-between mb-2 bg-header">
            <div className="col-6">
              <img src={Logo2} alt="" />
            </div>
            <div className="contact-form-title col-6">
              <div>{t("guestbook")}</div>
            </div>
          </div>
          <div className="bg-content">
            <div className="text-center mb-1 fw-bold fs-4">
              PIT IAI (Ikatan Apoteker Indonesia) 2024 “Weaving Progress:
              Integrating Pharmaceutical Sciences into The Global Health System”
            </div>
            {/* <div className="text-center fw-bold fs-5">
              Shangri-La Hotel Jakarta
            </div>
            <div className="text-center mb-1 fw-bold fs-5">
              July 26th & 27th, 2024
            </div> */}
            <div className="text-center mb-4">{t("guestbook_desc")}</div>
            <hr className="hr"></hr>
            <div className="mb-3 d-flex align-items-center">
              <span>English</span>
              <ReactSwitch
                offColor="rgb(0, 136, 0)"
                className="mx-3"
                uncheckedIcon={false}
                checkedIcon={false}
                checked={params.checked}
                onChange={handleChange}
              />
              <span>Bahasa</span>
            </div>
            <div className="mb-3">
              <label for="nama" className="form-label">
                {t("nama")}
              </label>
              <input
                type="text"
                {...register("nama")}
                className="form-control"
                id="nama"
              ></input>
              <small className="text-danger">{errors.nama?.message}</small>
            </div>
            <div className="mb-3">
              <label for="no_telepon" className="form-label">
                {t("no_telepon")}
              </label>
              <input
                type="text"
                {...register("no_telepon")}
                className="form-control"
                id="no_telepon"
                minLength={8}
                maxLength={12}
                onKeyUp={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              ></input>
              <small className="text-danger">
                {errors.no_telepon?.message}
              </small>
            </div>
            <div className="mb-3">
              <label for="instansi" className="form-label">
                {t("instansi")}
              </label>
              <input
                type="text"
                {...register("instansi")}
                className="form-control"
                id="instansi"
              ></input>
              <small className="text-danger">{errors.instansi?.message}</small>
            </div>
            <div className="mb-3">
              <label for="bagian" className="form-label">
                {t("bagian")}
              </label>
              <input
                type="text"
                {...register("bagian")}
                className="form-control"
                id="bagian"
              ></input>
              <small className="text-danger">{errors.bagian?.message}</small>
            </div>
            <div className="mb-3">
              <label for="pertanyaan" className="form-label">
                {t("question")}
              </label>
              <textarea
                {...register("pertanyaan")}
                className="form-control"
                id="pertanyaan"
                rows={5}
              ></textarea>
              <small className="text-danger">
                {errors.pertanyaan?.message}
              </small>
            </div>

            <div className="container-contact2-form-btn">
              <div className="wrap-contact2-form-btn">
                <div className="contact2-form-bgbtn"></div>
                <button
                  className="contact2-form-btn"
                  disabled={loading}
                  type="submit"
                >
                  {t("masuk")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="bg-background">{/* <img src={Background} /> */}</div>
    </Template>
  );
}

export default GuestBookPage;
