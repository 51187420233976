import { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import Logo2 from "../Assets/logo2.png";
import { useTranslate } from "react-translate";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from "../Assets/back.svg";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function CompanyProfileDetailPage(params) {
  let navigate = useNavigate();
  let { id } = useParams();
  let t = useTranslate(params?.checked ? "id" : "en");
  var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  var height =
    window.innerWidth > 0 ? window.innerHeight : window.screen.height;
  const [widthValue, setWidthValue] = useState(800);
  const [heightValue, setHeightValue] = useState(800);

  useEffect(() => {
    setWidthValue(width - 50);
    setHeightValue(height - 100);
  }, [width, height]);

  const data = [
    {
      id: 1,
      file: "https://drive.google.com/file/d/1KGKTY-Knzi5bExc1-croKwGNPruKnIIc/preview",
    },
    {
      id: 2,
      file: "https://drive.google.com/file/d/1AZl6R_rtN7dTiCY7tlsts8EyAyRw1Ipx/preview",
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2 bg-header">
        <div className="col-6">
          <img src={Logo2} alt="" />
        </div>
        <div className="contact-form-title col-6">
          <div className="d-flex align-items-center justify-content-end e-catalog">
            <div className="me-5">Company Profile</div>
            <div
              className="btn btn-secondary text-center px-1"
              onClick={() => navigate(-1)}
            >
              <img src={BackIcon} className="back-icon my-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="layout-catalog">
        <iframe
        sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
          src={data?.[id - 1]?.file}
          style={{ width: "100%", height: heightValue }}
          title="catalog"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
}

export default CompanyProfileDetailPage;
