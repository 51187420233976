import Template from "../Template";
import HelpIcon from "../Assets/help.svg";
import ImageIcon from "../Assets/image.svg";
import CompanyIcon from "../Assets/company.svg";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/logo.png";
import { useTranslate } from "react-translate";

function OptionPage(params) {
  let navigate = useNavigate();
  let t = useTranslate(params?.checked ? "id" : "en");

  return (
    <Template className="justify-content-center">
      <div className="row option-layout">
        <div className="col-md-12 mb-4">
          <div className="card" onClick={() => navigate("/option")}>
            <div className="card-body">
              <div className="row align-items-center justify-content-center">
                <div className="text-center col-md-5">
                  <img src={Logo} alt="" />
                </div>
                <div className="d-none d-md-block col-md-2 text-center">
                  <div className="vl"></div>
                </div>
                <span className="contact2-form-title pt-3 pt-md-0 pb-0 col-md-5">
                  {t("guestbook")}
                </span>
                <span className="col-md-12 text-center mt-3">
                  <div className="mb-1 fw-bold fs-4">
                    PIT IAI (Ikatan Apoteker Indonesia) 2024 “Weaving Progress:
                    Integrating Pharmaceutical Sciences into The Global Health
                    System”
                  </div>
                  {/* <div className="fw-bold fs-5">Shangri-La Hotel Jakarta</div>
                  <div className="mb-1 fw-bold fs-5">
                    July 26th & 27th, 2024
                  </div> */}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 mb-4">
          <div
            className="card"
            role="button"
            onClick={() => navigate("/company-profile")}
          >
            <div className="card-body">
              <img src={CompanyIcon} className="icon" alt="" />
              <div className="text mt-4">{t("compro")}</div>
              <span className="text-secondary">{t("compro_desc")}</span>
            </div>
          </div>
        </div> */}
        {/* <div className="col-md-4 mb-4">
          <div
            className="card"
            role="button"
            onClick={() => navigate("/inquiry")}
          >
            <div className="card-body">
              <img src={HelpIcon} className="icon" alt="" />
              <div className="text mt-4">Inquiry</div>
              <span className="text-secondary">{t("inquiry")}</span>
            </div>
          </div>
        </div> */}
        <div className="col-md-12">
          <div
            className="card"
            role="button"
            onClick={() => navigate("/e-catalog")}
          >
            <div className="card-body">
              <img src={ImageIcon} className="icon" alt="" />
              <div className="text mt-4">e-Catalog</div>
              <span className="text-secondary">{t("brosur")}</span>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}

export default OptionPage;
