import BackIcon from "../Assets/back.svg";
import Logo2 from "../Assets/logo2.png";
import Thumbnail1 from "../Assets/thumbnail/1.png";
import Thumbnail2 from "../Assets/thumbnail/2.png";
import Thumbnail3 from "../Assets/thumbnail/3.png";
import Thumbnail4 from "../Assets/thumbnail/4.png";
import Thumbnail5 from "../Assets/thumbnail/5.png";
import Thumbnail6 from "../Assets/thumbnail/6.png";
import Thumbnail7 from "../Assets/thumbnail/7.png";
import Thumbnail8 from "../Assets/thumbnail/8.png";
import Thumbnail9 from "../Assets/thumbnail/9.png";
import Thumbnail10 from "../Assets/thumbnail/10.png";
import Thumbnail11 from "../Assets/thumbnail/11.png";
import Thumbnail12 from "../Assets/thumbnail/12.png";
import Thumbnail13 from "../Assets/thumbnail/13.png";
import Thumbnail14 from "../Assets/thumbnail/14.png";
import Thumbnail15 from "../Assets/thumbnail/15.png";
import { useNavigate } from "react-router-dom";

function ECatalogPage(params) {
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      thumbnail: Thumbnail1,
    },
    {
      id: 2,
      thumbnail: Thumbnail2,
    },
    {
      id: 3,
      thumbnail: Thumbnail3,
    },
    {
      id: 4,
      thumbnail: Thumbnail4,
    },
    {
      id: 5,
      thumbnail: Thumbnail5,
    },
    {
      id: 6,
      thumbnail: Thumbnail6,
    },
    {
      id: 7,
      thumbnail: Thumbnail7,
    },
    {
      id: 8,
      thumbnail: Thumbnail8,
    },
    {
      id: 9,
      thumbnail: Thumbnail9,
    },
    {
      id: 10,
      thumbnail: Thumbnail10,
    },
    {
      id: 11,
      thumbnail: Thumbnail11,
    },
    {
      id: 12,
      thumbnail: Thumbnail12,
    },
    {
      id: 13,
      thumbnail: Thumbnail13,
    },
    // {
    //   id: 14,
    //   thumbnail: Thumbnail14,
    // },
    {
      id: 15,
      thumbnail: Thumbnail15,
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2 bg-header">
        <div className="col-6">
          <img src={Logo2} alt="" />
        </div>
        <div className="d-flex align-items-center justify-content-end e-catalog">
          <div className="me-5">E-Catalog</div>
          <div
            className="btn btn-secondary text-center px-1"
            onClick={() => navigate(-1)}
          >
            <img src={BackIcon} className="back-icon my-auto" alt="" />
          </div>
        </div>
      </div>
      <div className="row mx-auto align-items-center justify-content-center">
        {data?.map((item, index) => (
          <div className="col-12 col-md-3 p-4" key={index}>
            <img
              className="img-thumbnail"
              alt={index}
              onClick={() => navigate("/e-catalog/detail/" + item?.id)}
              src={item?.thumbnail}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ECatalogPage;
