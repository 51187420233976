import { useTranslate } from "react-translate";
import Logo2 from "../Assets/logo2.png";
import Tutorial1 from "../Assets/tutorial/1.png";
import Tutorial2 from "../Assets/tutorial/2.png";
import Tutorial3 from "../Assets/tutorial/3.png";
import Tutorial4 from "../Assets/tutorial/4.png";
import CancelIcon from "../Assets/tutorial/cancle.png";
import CardTutorial from "../Components/CardTutorial";
import { useState } from "react";

function EventPage(params) {
  const [tutor, setTutor] = useState(localStorage.getItem("tutorial"));
  let t = useTranslate(params?.checked ? "id" : "en");

  const tutorial = [
    {
      no: "01",
      icon: Tutorial1,
      name: "Mulai dengan mudah",
      description: "Klik ikon panah di layar untuk berjalan maju.",
    },
    {
      no: "02",
      icon: Tutorial2,
      name: "Geser atau Rotasikan",
      description:
        "Gunakan jari atau mouse untuk menggeser atau memutar sudut pandang saat menjelajah.",
    },
    {
      no: "03",
      icon: Tutorial3,
      name: "Petunjuk Informasi Virtual Booth BBraun",
      description:
        "Temukan dan klik ikon 'i' untuk mendapatkan informasi detail terkait objek yg sedang dilihat.",
    },
    {
      no: "04",
      icon: Tutorial4,
      name: "Memutar Video",
      description:
        "Temukan dan klik ikon play video untuk memutar video pada televisi.",
    },
  ];

  return (
    <div className="lobby">
      <iframe
        title="360file"
        src={process.env.REACT_APP_FRONTEND_URL + "3d/index.htm"}
        allowFullScreen
      ></iframe>
      {!tutor && (
        <div className="tutorial">
          <div
            className="close-icon"
            onClick={() => {
              localStorage.setItem("tutorial", true);
              setTutor(true);
            }}
          >
            <img src={CancelIcon} alt="" />
          </div>
          <div className="header">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="col-6">
                <img src={Logo2} alt="" />
              </div>
              <div className="contact-form-title col-6">
                <div>{t("tutorial")}</div>
              </div>
            </div>
          </div>
          <div className="body row gx-3">
            {tutorial?.map((item, index) => (
              <CardTutorial index={index} item={item} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default EventPage;
