import Logo2 from "../Assets/logo2.png";
import Thumbnail1 from "../Assets/company_profile/1.png";
import Thumbnail2 from "../Assets/company_profile/2.png";
import BackIcon from "../Assets/back.svg";
import { useNavigate } from "react-router-dom";

function CompanyProfilePage(params) {
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      thumbnail: Thumbnail1,
      file: "https://drive.google.com/file/d/1AZl6R_rtN7dTiCY7tlsts8EyAyRw1Ipx/preview",
    },
    {
      id: 2,
      thumbnail: Thumbnail2,
      file: "https://drive.google.com/file/d/1KGKTY-Knzi5bExc1-croKwGNPruKnIIc/preview",
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2 bg-header">
        <div className="col-6">
          <img src={Logo2} alt="" />
        </div>
        <div className="d-flex align-items-center justify-content-end e-catalog">
          <div className="me-5">Company Profile</div>
          <div
            className="btn btn-secondary text-center px-1"
            onClick={() => navigate(-1)}
          >
            <img src={BackIcon} className="back-icon my-auto" alt="" />
          </div>
        </div>
      </div>
      <div className="row mx-auto align-items-center justify-content-center">
        {data?.map((item, index) => (
          <div className="col-12 col-md-3 p-4" key={index}>
            <img
              className="img-thumbnail"
              alt={index}
              onClick={() => navigate("/company-profile/detail/" + item?.id)}
              src={item?.thumbnail}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompanyProfilePage;
